<script setup>
import { APPSTORES_FORWARDING_URL } from "~/services/constants"
const localePath = useLocaleRoute()
</script>

<template>
  <section id="badges-section" class="badges-section">
    <div class="badge-section-container">
      <h2 class="is-size-2 is-size-3-mobile py-2 badge-heading">
        {{ $t("supporters.support") }}
      </h2>
      <p class="is-size-5 is-size-6-mobile pb-4">
        {{ $t("supporters.supportSubtitle") }}
      </p>
      <div class="columns is-centered is-vcentered supporters">
        <div class="column has-text-centered is-third">
          <a href="https://hpi.de/" target="_blank">
            <img
              id="hpi-logo"
              :alt="$t('supporters.alt.hpi')"
              class="supporter-logo"
              src="~assets/images/supporter_logos/hpi_logo.webp"
            />
          </a>
        </div>
        <div class="column has-text-centered is-third">
          <a href="https://www.spinlab.co/" target="_blank">
            <img
              id="spinlab-logo"
              :alt="$t('supporters.alt.spinLab')"
              class="supporter-logo"
              src="~assets/images/supporter_logos/spinlabLogo.webp"
            />
          </a>
        </div>
        <div class="column has-text-centered is-thrid">
          <a href="https://cse.cbs.dk/" target="_blank">
            <img
              id="cse-logo"
              :alt="$t('supporters.alt.cse')"
              class="supporter-logo"
              src="~assets/images/supporter_logos/cse_logo.webp"
            />
          </a>
        </div>
      </div>
      <div class="columns is-centered is-vcentered supporters">
        <div class="column has-text-centered is-third">
          <a href="https://www.5-ht.com/" target="_blank">
            <img
              id="5ht-logo"
              :alt="$t('supporters.alt.5ht')"
              class="supporter-logo"
              src="~assets/images/supporter_logos/logo5HT.webp"
            />
          </a>
        </div>
        <div class="column has-text-centered is-third">
          <a href="https://www.mth-potsdam.de/" target="_blank">
            <img
              id="mth-logo"
              :alt="$t('supporters.alt.mth')"
              class="supporter-logo"
              src="~assets/images/supporter_logos/mth-logo.svg"
            />
          </a>
        </div>
        <div class="column has-text-centered is-third">
          <a href="https://www.uni-potsdam.de/potsdam-transfer/" target="_blank">
            <img
              id="pt-logo"
              :alt="$t('supporters.alt.pt')"
              class="supporter-logo"
              src="~assets/images/supporter_logos/potsdam_transfer.webp"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="badge-section-container">
      <h2 class="is-size-2 is-size-3-mobile py-2 badge-heading">
        {{ $t("supporters.sponsored") }}
      </h2>
      <p class="is-size-5 is-size-6-mobile pb-4">
        {{ $t("supporters.sponsoredSubtitle") }}
      </p>
      <NuxtLink :to="localePath('supporters')">
        <button class="button is-brandpurple is-rounded mb-3">
          {{ $t("supporters.furtherSupport") }}
        </button>
      </NuxtLink>
      <div class="columns is-vcentered has-text-centered">
        <div class="column">
          <img
            id="exist-logos"
            :alt="$t('supporters.alt.exist')"
            src="~assets/images/supporter_logos/exist-logos.webp"
          />
        </div>
        <div class="column is-one-fifth">
          <img
            id="bafa"
            :alt="$t('supporters.alt.bafa')"
            class="supporter-logo"
            src="~assets/images/supporter_logos/bafaInvest.webp"
          />
        </div>
      </div>
      <div v-if="showAllSupporters" class="columns mt-4">
        <div class="column is-flex is-justify-content-center">
          <div class="is-flex is-flex-direction-column eu-bb-container">
            <img
              class="eu-logo"
              :alt="$t('supporters.alt.eu')"
              src="~assets/images/supporter_logos/cofinanced-eu.webp"
            />
            <img
              class="bb-logo"
              :alt="$t('supporters.alt.eu')"
              src="~assets/images/supporter_logos/brb-bildmarke.webp"
            />
          </div>
          <div>
            {{ $t("supporters.inno2022") }}
          </div>
        </div>
      </div>
    </div>
    <div class="badge-section-container">
      <h2 class="is-size-2 is-size-3-mobile py-2 badge-heading">
        {{ $t("supporters.awarded") }}
      </h2>
      <p class="is-size-5 is-size-6-mobile pb-6">
        {{ $t("supporters.awardedSubtitle") }}
      </p>
      <div class="columns is-vcentered has-text-centered">
        <div class="column is-half">
          <img class="award-logo" :alt="$t('supporters.alt.S4L')" src="~assets/images/supporter_logos/logoS4L.webp" />
        </div>
        <div class="column is-half">
          <img
            class="award-logo-bpw"
            :alt="$t('supporters.alt.bpw')"
            src="~assets/images/supporter_logos/bpwbb-logo.svg"
          />
        </div>
      </div>
    </div>

    <div class="has-text-centered my-6">
      <a :href="appstoresForwardingUrl" target="_blank">
        <button class="button is-responsive is-brandpurple is-rounded is-large btn is-responsive has-text-centered">
          {{ $t("supporters.buttonAppstoresLink") }}
        </button>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "MemodioSupporterRows",
  props: {
    showAllSupporters: { type: Boolean, default: false },
  },
  data() {
    return {
      appstoresForwardingUrl: APPSTORES_FORWARDING_URL,
    }
  },
}
</script>

<style lang="scss" scoped>
.social-fond {
  max-height: 120px;
}

#bafa {
  max-width: 120px;
}

.award-logo {
  max-height: 150px;
}

.eu-bb-container {
  min-width: 300px;
}

.eu-logo {
  object-fit: cover;
  margin-top: 1rem;
  margin-right: 2rem;
}

.bb-logo {
  max-height: 120px;
  width: auto;
  object-fit: scale-down;
  margin-top: 2rem;
}

.award-logo-bpw {
  max-height: 100px;
}

.badge-heading {
  font-weight: 600;
  padding-bottom: 40px;
}

.supporter-subtitle {
  font-weight: 600;
}

.badge-section-container {
  padding-top: 64px;
  padding-bottom: 40px;
  border-top: 2px solid rgba(29, 29, 29, 0.15);
}
.badge-section-container {
  p {
    margin-top: 8px;
  }
}

.supporters {
  img {
    max-height: 120px;
  }
}
</style>
